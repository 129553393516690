<template>
  <div class="p-2">
    <h1>{{ title }}</h1>
    <router-link class="text-blue-500" :to="'/list/'+table+'/'"><i class="las la-arrow-left"></i> {{ $schema[table].title }}</router-link>
    <br>
    <h2>{{ numselected }}db {{ $schema[table].title_single }} kiválasztva:</h2>
    <div v-if="loading" class="w-screen">
      <div class="w-32 h-32 text-primary" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
        <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
        <path
            d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
        />
        </svg>
      </div>
    </div>
    <div v-if="!loading" style="user-select: text;">
      <div v-if="buttons.length > 0" class="mt-8">
        <router-link v-for="bt in buttons" :key="bt.route" :to="bt.route"><div :class="'mt-2 mr-2 p-2 px-8 rounded-md inline-block '+(bt.bg?bt.bg:'bg-blue-800')+' '+(bt.text?bt.text:'text-white')">
          {{ bt.title }}
        </div></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Command from './command.js'

const courier_codes = {
  gls: 'gls',
  sameday: 'sameday',
  fan: 'fan',
  packeta: 'packeta',
  samedaylocker: 'sameday',
  packetapoint: 'packeta',
  packeta_mpl: 'packeta',
  posta: 'posta',
  office: 'internetprint',
}

export default {
  name: 'Stat',
  mixins: [Command],
  computed: {
    table() { return this.$route.params.table },
    command() { return this.$route.params.command },
    param() { return this.$route.params.param },
    param2() { return this.$route.params.param2 },
    statusTitle() { return this.$schema[this.table].fields.status.values[this.command].title },
    statusColor() {
      let sta = this.$schema[this.table].fields.status.values[this.command]
      if (sta) {
        return sta.color1
      } else {
        return 'blue-800'
      }
    },
    statusText() {
      let sta = this.$schema[this.table].fields.status.values[this.command]
      if (sta) {
        return sta.colort
      } else {
        return 'white'
      }
    },
    commandTitle() {
      let op = this.$schema[this.table].ops[this.command.replace('-','')]
      if (op) {
        return op.title
      } else {
        return '-'
      }
    },
    title() {
      if (!this.command) {return '-'}
        if (this.command.indexOf('-') == 0) {
          return this.commandTitle
        } else {
          return this.statusTitle
        }
    },
    statuses() {
      let op = this.$schema[this.table].ops[this.command.replace('-','')]
      if (op) {
        if (op.statuses) {
          let l = []
          op.statuses.forEach(s => {
            let sta = this.$schema[this.table].fields.status.values[s]
            let title = sta.title
            l.push({command: s, title: title, bg: sta.color1, text: sta.colort})
          })
          return l
        } else {
          return []
        }
      } else {
        return []
      }
    },
    buttons() {
      let l = []
      let op = this.$schema[this.table].ops[this.command.replace('-','')]
      if (op.buttons) { l = op.buttons }
      return l
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh()
    })
  },
  watch: {
    $route() {
        this.$nextTick(() => {
          this.refresh()
        })
    }
  },
  methods: {
    downloadFile(data, mime, filename) {
        let file
        try {
            file = new File([data], filename, {type:mime})
        } catch (e) {
            file = new Blob([data], {type:mime})
        }
        let url = URL.createObjectURL(file)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
    },
    async refresh() {
      let e = this.update
      this.loading = true

      if (this.param == 'go') {
        let type = this.param2
        console.log("GO", type)
        this.loading = true
        let tnow = new Date().toLocaleDateString('hu-HU', {
                    year: 'numeric',
                    month:	"short",
                    day: '2-digit',
                    hour:	"2-digit",
                    minute:	"2-digit",
                })
        let w = this.$excel()
        let tit = this.$schema[this.table].title_single
        let s = await w.addWorksheet(tit, {
            headerFooter:{oddFooter: tit + ' ' + tnow + ' &P. / &N oldal'},
            properties: {
                defaultColWidth: 13,
            }, pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                fitToPage: true,
                fitToWidth: 1,
                margins: {
                    left: 0.197, right: 0.197,
                    top: 0.197, bottom: 0.197,
                    header: 0.0, footer: 0.197
                },
            }
        })
        let r
        let colws = []
        let colalign = []
        if (type == 'stat') {
          r = s.addRow([
            'Dátum',
            '#Rendelés',
            'Állapot kód',
            'Állapot',
            'Fiz mód kód',

            'Fiz. áll. kód',
            'Sz. mód',
            'Szállító',
            '#Számla',
            'db',

            '1',
            'Termék kód',
            'Termék',
            'Teljes Ár',
            'Pnem.',

            'HUF ár',
            'orsz.',
            'Név',
            'Email',
            'Telefon',

            'Megjegyzés'
          ])
          colws    = [
             12, 12, 11, 12, 12,
             10, 10, 13, 15, 5,
             2, 15, 20, 10, 6,
             10, 4, 17, 25, 15,
             30
            ]
          colalign = [
            'left', 'left', 'left', 'left', 'left',
            'left', 'left', 'left', 'left', 'right',
            'right', 'left', 'left', 'right', 'left',
            'right', 'left', 'left', 'left', 'left',
            'left'
          ]
        } else {
          r = s.addRow([
            'Dátum',
            '#Rendelés',
            'Név',
            'Ország',
            'Termék',

            'db',
            'Állapot',
          ])
          colws = [
            10, 10, 10, 10, 10,
            10, 10
          ]
          colalign = [
            'left', 'left', 'left', 'left', 'left',
            'right', 'left'
          ]
        }
        r.border = {
            top: {style:'medium'},
            left: {style:'thin'},
            bottom: {style:'medium'},
            right: {style:'thin'}
        }
        r.font = {
            name: 'Calibri',
            size: 11,
            bold: true
        }
        r.alignment = { vertical: 'top', /*horizontal: 'left',*/ wrapText: true }
        r.height = 30

        s.columns.forEach(function (column, i) {
          column.width = colws[i]
          column.alignment = { horizontal: colalign[i] }
        })

        let rates = this.$store.state._rates.rates
        let sta = this.$schema[this.table].fields.status.values
        console.log("sta:", sta)
        this.list.sort((a, b) => {
          return b.created - a.created
        })
        this.list.forEach(i => {
          let pcs = parseInt(i.item.quote.pcs)
          let sp = i.smart_product
          /*
          const ivpp = {
            'A3': 13,
            'A4': 7,
            'desktop': 5
          }
          let iv = ivpp[sp]
          if (!iv) {
            iv = 0
          }
          iv *= pcs
          */
         if (type == 'stat') {
            r = s.addRow([
              new Date(i.created*1000).toLocaleDateString('hu-HU', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                  }),
                i.code,
                i.status,
                sta[i.status].title,
                i.payment_type,
                i.payment_status,
                i.courier,
                courier_codes[i.courier],
                (i.invoices.length ? i.invoices[0].number : ''),
                pcs,
                1,
                i.smart_product,
                this.$productNameShort(i),
                i.item.quote.price,
                i.item.quote.currency,
                Math.round(i.item.quote.price / rates[i.item.quote.currency] * rates.HUF),
                i.shipping_country,
                i.contact_name,
                i.contact_email,
                i.contact_phone,
                i.note
              ])
          } else {
            r = s.addRow([
              new Date(i.created*1000).toLocaleDateString('hu-HU', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                  }),
                i.code,
                i.contact_name,
                i.shipping_country,
                this.$productNameShort(i),
                pcs,
                sta[i.status].title,
              ])

          }
          r.border = {
            top: {style:'thin'},
            left: {style:'thin'},
            bottom: {style:'thin'},
            right: {style:'thin'}
          }
          r.font = {
              name: 'Calibri',
              size: 11,
          }
          r.alignment = { vertical: 'middle', /*horizontal: 'left',*/ shrinkToFit: true }
            
        })

        const buffer = await w.xlsx.writeBuffer()
        this.downloadFile(buffer, 'application/excel', type=='stat' ? 'statisztika.xlsx' : 'koteszeti.xlsx')
        this.loading = false
      } else {
        await this.reload()
      }
      document.title = this.title
      this.update++
    },
    
  }
  
}
</script>
