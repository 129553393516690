<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="p-2">
    <h1>{{ title }}</h1>
    <router-link class="text-blue-500" :to="'/list/'+table+'/'"><i class="las la-arrow-left"></i> {{ $schema[table].title }}</router-link>

    <div v-if="loading" class="w-screen">
      <div class="w-32 h-32 text-primary" style="position: absolute; opacity: 0.2; top: 50%; left: 50%; margin-left: -4rem; margin-top: -4rem">
        <svg class="w-32 h-32 animate-spin" viewBox="0 0 32 32">
        <path
            d="M 18 4.179688 L 18 6.203125 C 22.558594 7.132813 26 11.171875 26 16 C 26 21.515625 21.515625 26 16 26 C 10.484375 26 6 21.515625 6 16 C 6 11.171875 9.441406 7.132813 14 6.203125 L 14 4.179688 C 8.335938 5.136719 4 10.066406 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 10.066406 23.664063 5.136719 18 4.179688 Z"
        />
        </svg>
      </div>
    </div>
    <div v-if="!loading">

    <div v-if="!param">
        <br>
        <h2>{{ numpackages }}db Csomag kiválasztva.</h2>
        <div class="mb-8">
        <h3>Kijelölt csomagokat:</h3>
            <a @click="collect"><div class="inline-block p-2 px-8 mt-2 mr-2 text-white bg-green-800 rounded-md">
                Összevon egy csomagba
            </div></a>
            <a @click="separate"><div class="inline-block p-2 px-8 mt-2 text-white bg-red-800 rounded-md">
                Szétválaszt
            </div></a>
        </div>

        <table v-if="packagesByPostcode" class="w-full border-b-2 border-gray-600" style="user-select: text;">
            <tr class="font-bold border-t-2 border-b-2 border-gray-800">
            <td>
                <span></span>
            </td>
            <td v-for="field in listFieldsP" :key="field.name" :class="(field.name=='_shipping_address' || field.name=='shipping_name' ? 'w-full': '') + ' px-2 align-top whitespace-nowrap'">
                <span v-html="field.title"></span>
            </td>
            </tr>
            <template v-for="(p, pindex) in packagesByPostcode">
                <template v-for="item in p.list" :key="item._id">
                    <tr :class="'border-gray-800 border-b '+ (p.list.length > 1 ? (pindex % 2 ? 'bg-gray-800': 'bg-gray-600')+' text-white':(item.index % 2 ? 'bg-gray-100':''))">
                        <td>
                            <div class="p-1"><input type="checkbox" :checked="oselect[item._id]?'checked':false" @click="toggleSelect(item)" /></div>
                        </td>
                        <td v-for="field in listFieldsP" :key="field.name" :class="(field.name=='_priceh' ? 'whitespace-nowrap': '') + ' p-1 align-top border-r border-gray-500'">
                        <span :class="'tabular-nums '+(field.name=='shipping_postcode' && postcodeCount[item.shipping_postcode] > 1 ? 'font-bold text-red-500 ':'')" v-html="formatValue(field,item[field.name])"></span>
                        </td>
                    </tr>
                </template>
            </template>
        </table>
      </div>

      <router-link v-if="!param" :to="'/packaging/go'"><div class="inline-block p-2 px-8 mt-8 text-white bg-blue-800 rounded-md">
        Tovább a címkézéshez
      </div></router-link>

      <div v-if="param=='go'">
          <br>
          <h2>Csomagoláshoz szükséges fájlok letöltése:</h2>
          <a @click="downloadSummary()" class="flex items-center py-2 w-min whitespace-nowrap" ><i class="pr-2 text-4xl text-green-600 las la-file-excel"></i> Csomag lista</a>
          <a v-if="countPackages('gls') > 0" @click="downloadGLS()" class="flex items-center py-2 w-min whitespace-nowrap" ><i class="pr-2 text-4xl text-green-600 las la-file-csv"></i> GLS címkék ({{ countPackages('gls') }} db)</a>
          <a v-if="countPackages('sameday','samedaylocker') > 0 && samedayStatus=='finished'" :href="$config.api.cloud + 'label/' + samedayBatchName + '/' + samedayBatchName + '.pdf'" target="_blank" class="flex items-center py-2 w-min whitespace-nowrap" ><i class="pr-2 text-4xl text-red-600 las la-file-pdf"></i> Sameday címkék ({{ countPackages('sameday','samedaylocker') }} db) {{ samedayText}}</a>
          <a v-if="countPackages('fan') > 0 && samedayStatus=='finished'" :href="$config.api.cloud + 'label/' + samedayBatchName + '/' + samedayBatchName + '-fan.pdf'" target="_blank" class="flex items-center py-2 w-min whitespace-nowrap" ><i class="pr-2 text-4xl text-red-600 las la-file-pdf"></i> FAN címkék ({{ countPackages('fan') }} db) {{ samedayText}}</a>
          <a v-if="countPackages('packeta','packeta_mpl','packetapoint') > 0 && samedayStatus=='finished'" :href="$config.api.cloud + 'label/' + samedayBatchName + '/' + samedayBatchName + '-packeta.pdf'" target="_blank" class="flex items-center py-2 w-min whitespace-nowrap" ><i class="pr-2 text-4xl text-red-600 las la-file-pdf"></i> PACKETA címkék ({{ countPackages('packeta','packeta_mpl', 'packetapoint') }} db) {{ samedayText}}</a>
          <div v-if="samedayStatus=='processing' || samedayStatus=='wait_processing'" class="flex items-center text-red-600 w-min whitespace-nowrap">
              <i class="text-4xl las la-sync la-spin"></i><div class="pl-2">Sameday/FAN/Packeta címke készítés folyamatban...</div>
          </div>
          <a v-if="countPackages('posta') > 0" @click="downloadPosta()" class="flex items-center py-2 w-min whitespace-nowrap" ><i class="pr-2 text-4xl text-red-600 las la-file-pdf"></i> Posta címkék ({{ countPackages('posta') }} db)</a>
          <router-link :to="'/packaging/upload'"><div class="inline-block p-2 px-8 mt-8 text-white bg-blue-800 rounded-md">
            Tovább a fuvarlevélszámok feltöltéséhez
          </div></router-link>
      </div>

      <div v-if="param=='upload'">
          <br>
          <h2>Fuvarlevélszámok feltöltése</h2>
          <i>GLS-ből exportált, ÉÉÉÉHHNN.csv: </i><br>
          <input type="file" ref="uploader" class="w-96" accept=".csv" />
          <br>
          <a @click="uploadTracking()" class="inline-block p-2 px-8 mt-8 text-white bg-blue-800 rounded-md"> Feltöltés megkezdése </a>
      </div>
    </div>
  </div>
</template>

<script>
const countryList = require('./countrylist-en.json')
const FontFaceObserver = require('fontfaceobserver')
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
function countryName(cc) {
    let cd = countryList.countries[cc]
    return cd.n.toUpperCase()
}

import Command from './command.js'

export default {
  name: 'Package',
  mixins: [Command],
  data() {
        return {
            oselect: {},
            packagesByPostcode: false,
            postcodeCount: {},
            samedayStatus: 'idle',
            samedayBatchName: '',
            samedayText: '',
        }
    },
  computed: {
    table() { return 'orders' },
    command() { return 'packaging' },
    param() { return this.$route.params.param },
    title() { return "Csomagolás"},
    numpackages() {
        return Object.keys(this.packages).length
    },
    packages() {
        let e = this.update
        let ind = 0
        let plist = {}
        this.list.forEach(i => {
            let quote = i.item.quote
            let currency = quote.currency
            i._pcs = quote.pcs
            i._product = this.$productNameShort(i)
            if (i.courier == 'samedaylocker' && i.locker) {
                i._shipping_address = `Sameday #${i.locker.lockerId} ${i.locker.name}`
            } else if (i.courier == 'packetapoint' && i.packetapoint) {
                i._shipping_address = `Packeta #${i.packetapoint.id} ${i.packetapoint.place} ${i.packetapoint.name}`
            } else {
                i._shipping_address = i.shipping_address1 + (i.shipping_address2 ? ' '+i.shipping_address2 : '')
            }
            i.shipping_note = i.shipping_note ? i.shipping_note : ''
            i._price = this.$price(quote.product_price, currency) +' + '+ this.$price(quote.shipping_price, currency)
            i._priceh = i._price
            let isDeleted = i.status.indexOf('deleted') != -1
            i._deleted = isDeleted
            if (i.item.settings.shipping_free) {i._priceh += ' <span class="p-1 bg-blue-400 rounded">KEDV.SZ.</span>'}
            if (!plist[i.package]) {
                plist[i.package] = {
                    package: i.package,
                    payment_type: i.payment_type,
                    courier: i.courier,
                    name: i.shipping_name,
                    country: i.shipping_country,
                    region: i.shipping_region,
                    postcode: i.shipping_postcode,
                    city: i.shipping_city,
                    address1: i.shipping_address1,
                    address2: i.shipping_address2,
                    contact_name: i.contact_name,
                    email: i.contact_email,
                    phone: i.contact_phone,
                    note: i.shipping_note,
                    list: [],
                    smart_list: i.smart_list,
                    total_price: isDeleted ? 0 : quote.price,
                    currency: currency
                }
            } else {
                if (!isDeleted) {
                    plist[i.package].total_price += quote.price
                }
            }
            i.index = ind++
            plist[i.package].list.push(i)
        })
        return plist
    },
    listFieldsP() {
        let fields = this.$schema[this.table].fields
        let specfields = {
            '_pcs':{ type:'int', title: 'db'},
            'shipping_postcode':{ type:'text', title: 'IRSZ'},
            'shipping_city':{ type:'text', title: 'Város'},
            '_shipping_address':{ type:'text', title: 'Cím'},
            'shipping_name':{ type:'text', title: 'Címzett Név'},
            '_priceh':{ type:'text', title: 'Ár'},
            '_product':{ type:'text', title: 'Termék'},
        }
        let needf = ['package', 'shipping_country', 'shipping_postcode', 'shipping_city', '_shipping_address', 'shipping_name', 'code','created','_pcs', '_product','_priceh']
        let lf = []
        needf.forEach(fn => {
            if (fields[fn]) {
                fields[fn].name = fn
                lf.push(fields[fn])
            } else {
                specfields[fn].name = fn
                lf.push(specfields[fn])
            }
        })
        return lf
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refresh()
      this.statusCheck()
      window.setInterval(() => {
        this.statusCheck()
      }, 5000)
    })
  },
  watch: {
    $route() {
        this.$nextTick(() => {
          this.refresh()
        })
    }
  },
  methods: {
    toggleSelect(item) {
        if (this.oselect[item._id]) {
            delete this.oselect[item._id]
        } else {
            this.oselect[item._id] = {package: item.package}
        }
        
        this.update++
    },
    clearSelect() {
        this.oselect = {}
        this.update++
    },
    async collect() {
        this.loading = true
        let plist = []
        for (let id in this.oselect) {
            plist.push(this.oselect[id].package)
        }
        if (plist.length > 0) {
            let np = await this.newpackage(1)
            if (np > 0) {
                const res = await this.$store.API('admin/update', {
                            set: {
                                package: np,
                                smart_list: 7,
                                multiple_items: true
                            },
                            table: this.table,
                            filter: {package: {$in: plist}},
                        })
                if (!res) {
                    this.$message('error', 'Művelet sikertelen!')
                } else {
                    this.$message('info', 'Csomagok összevonva.')
                }
            }
            this.clearSelect()
            await this.refresh()
        }
        this.loading = false
    },
    async separate() {
        this.loading = true
        let idlist = []
        for (let id in this.oselect) {
            if (this.oselect[id]) {
                idlist.push(id)
            }
        }
        if (idlist.length > 0) {
            let np = await this.newpackage(idlist.length)
            if (np > 0) {
                let np0 = np - idlist.length + 1
                np = np0
                for (let pp = 0; pp < idlist.length; pp++) {
                    let id = idlist[pp]
                    let res = await this.$store.API('admin/update', {
                            set: {package: np},
                            table: this.table,
                            filter: {_id: id},
                        })
                    console.log('_id:',id,'np:',np, res)
                    np++
                }
                this.$message('info', 'Csomagok szétválasztva')
                this.clearSelect()
            }
            await this.refresh()
        }
        this.loading = false
    },
    async newpackage(count=1) {
        const res = await this.$store.API('admin/newpackage', { count: count })
        if (res) {
            return res.package
        } else {
            this.$message('error', 'Művelet sikertelen!')
            return -1
        }
    },
    calcPackagesByPostcode() {
        let plist1 = this.packages
        let plist = []
        for (let pid in plist1) {
            let p = plist1[pid]
            p.pid = pid
            plist.push(p)
        }
        plist = plist.sort((a, b) => {
            let dec = a.postcode > b.postcode ? 1 : -1
            if (a.postcode == b.postcode) {
                dec = a.name > b.name ? 1 : -1
            }
            return dec
        })
        this.postcodeCount = {}
        plist.forEach(p => {
            if (this.postcodeCount[p.postcode]) {
                this.postcodeCount[p.postcode]++
            } else {
                this.postcodeCount[p.postcode] = 1
            }
        })
        this.packagesByPostcode = plist
    },
    countPackages(courier, courier2) {
        let plist = this.packages
        let c = 0
        for (let pid in plist) {
            let p = plist[pid]
            if (p.courier == courier || p.courier == courier2) {
                c++
            }
        }
        return c
    },
    async statusCheck() {
        if (this.samedayStatus != 'processing') { return; }
        let resp = await this.$store.processAPI('status-label.json')
        if (resp) {
            if (resp.code == 'finished') {
                this.samedayBatchName = resp.data.name
                this.samedayStatus = 'finished'
                this.samedayText = ''
                console.log("LABEL FINISHED!")
            } else {
                this.samedayBatchName = false
                this.samedayText = resp.text
            }
            console.log("Label status check", resp, "sameday status:", this.samedayStatus)
        }
    },
    async downloadSummary() {
        const coname = {
            'gls': 'GLS',
            'sameday': 'Sameday',
            'fan': 'FAN (RO)',
            'samedaylocker': 'Sameday/Easybox',
            'packeta': 'Packeta',
            'packeta_mpl': 'Packeta / MPL',
            'packetapoint': 'Packeta Pont',
            'posta': 'Posta',
            'office': 'Telephely'
        }
        this.loading = true
        let tnow = new Date().toLocaleDateString('hu-HU', {
                    year: 'numeric',
                    month:	"short",
                    day: '2-digit',
                    hour:	"2-digit",
                    minute:	"2-digit",
                })

        let w = this.$excel()
        let s = await w.addWorksheet('Csomag lista' , {
            headerFooter:{oddFooter: 'Csomag lista ' + tnow + ' &P. / &N oldal'},
            properties: {
                defaultColWidth: 13,
            }, pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                fitToPage: true,
                fitToWidth: 1,
                margins: {
                    left: 0.197, right: 0.197,
                    top: 0.197, bottom: 0.197,
                    header: 0.0, footer: 0.197
                },
            }
        })

        let col = s.getColumn('A')
            col.width = 1.5

        col = s.getColumn('B')
            col.width = 3.5

        col = s.getColumn('C')
            col.width = 30

        col = s.getColumn('D')
            col.width = 8

        col = s.getColumn('E')
            col.width = 20

        col = s.getColumn('F')
            col.width = 15

        col = s.getColumn('G')
            col.width = 60

        col = s.getColumn('H')
            col.width = 13

        col = s.getColumn('I')
            col.width = 13

        col = s.getColumn('J')
            col.width = 18

        col = s.getColumn('K')
            col.width = 18

        let r
        
        function headerRow(list) {
            r = s.addRow(['', '['+list+'] Csomag lista ' + tnow])
            r.font = {
                name: 'Calibri',
                size: 12,
                bold: true
            }

            r = s.addRow(['L', 'MG', 'Címzett', 'Db', 'Termék', 'Szállító', 'Cím', '#Rendelés', 'Megr.d', 'Ár', 'Fiz.mód', ])
            r.border = {
                top: {style:'medium'},
                left: {style:'thin'},
                bottom: {style:'medium'},
                right: {style:'thin'}
            }
            r.font = {
                name: 'Calibri',
                size: 10,
                bold: true
            }
            r.alignment = { vertical: 'middle', horizontal: 'left', shrinkToFit: true }
            r.height = 22
        }


        let plist1 = this.packages
        let plist = []
        for (let pid in plist1) {
            let p = plist1[pid]
            p.pid = pid
            plist.push(p)
        }
        plist = plist.sort((a, b) => {
            return (a.smart_list+'-'+a.name) > (b.smart_list+'-'+b.name) ? 1 : -1
        })

        let smart_list_now = plist[0].smart_list

        headerRow(smart_list_now)

        for (let pind = 0; pind < plist.length ; pind++) {
            let p = plist[pind]
            if (p.smart_list != smart_list_now) {
                if (r) {
                    r.addPageBreak();
                }
                headerRow(p.smart_list)
                smart_list_now = p.smart_list
            }
            let fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FFFFFFFF'}
            }
            if (p.list.length > 1) {
                fill.fgColor.argb = pind % 2 ? 'FF999999' : 'FFCCCCCC'
            }
            p.list.forEach(o => {
                let ttext = new Date(o.created * 1000).toLocaleDateString('hu-HU', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                })
                r = s.addRow([o.smart_list, this.$monogram(o.shipping_name, o.billing_name), o.shipping_name ? o.shipping_name : o.billing_name, o._pcs, o._product, coname[o.courier], this.$af(o, false), o.code, ttext, o._price, this.$paymentType(o)])
                r.border = {
                    top: {style:'thin'},
                    left: {style:'thin'},
                    bottom: {style:'thin'},
                    right: {style:'thin'}
                }
                r.font = {
                    name: 'Calibri',
                    size: 11,
                }
                r.alignment = { vertical: 'middle', horizontal: 'left', shrinkToFit: true }
                r.fill = fill
            })
        }

        const buffer = await w.xlsx.writeBuffer()
        this.downloadFile(buffer, 'application/excel', 'csomaglista.xlsx')
        this.loading = false
    },
    rounding(currency, value) {
        if (currency == 'HUF') {
            value = Math.round(Math.floor(value / 10) * 10)
            return value.toFixed(0)
        } else {
            return value.toFixed(2)
        }
    },
    ajav(str) {
        /*
        àÀèÈìÌòÒùÙȕȔ
        */
        str = '' + str
        str = str.replace(/à+/ug, 'á')
        str = str.replace(/À+/ug, 'Á')
        str = str.replace(/è+/ug, 'é')
        str = str.replace(/È+/ug, 'É')
        str = str.replace(/ì+/ug, 'í')
        str = str.replace(/Ì+/ug, 'Í')
        str = str.replace(/ò+/ug, 'ó')
        str = str.replace(/Ò+/ug, 'Ó')
        str = str.replace(/ù+/ug, 'ú')
        str = str.replace(/Ù+/ug, 'Ú')
        str = str.replace(/ȕ+/ug, 'ű')
        str = str.replace(/Ȕ+/ug, 'Ű')
        return str
    },
    async downloadGLS() {
        this.loading = true
        let w = this.$excel()
        let s = w.addWorksheet('GLS')

        let plist1 = this.packages
        let plist = []
        for (let pid in plist1) {
            let p = plist1[pid]
            p.pid = pid
            plist.push(p)
        }
        plist = plist.sort((a, b) => {
            return (a.smart_list+a.name) > (b.smart_list+b.name) ? -1 : 1
        })

        let r
        for (let pind = 0; pind < plist.length ; pind++) {
            let p = plist[pind]
            if (p.courier == 'gls') {
                r = s.addRow([p.payment_type == 'cod' ? this.rounding(p.currency, p.total_price) : 0, this.ajav(p.name)+' [L'+p.smart_list+']', p.postcode, this.ajav(p.city), this.ajav(p.address1 + (p.address2 ? ' '+p.address2:'')), 'P'+p.package+'_'+p.list[0].code, 'P'+p.package, p.email, "'"+p.phone, p.country, p.note])
            }
        }
        const buffer = await w.csv.writeBuffer({
            formatterOptions: {
                delimiter: ';',
                quote: false,
            },
        })
        let text = buffer.toString()
        let buf = this.$iconv.encode(text, 'win1250');
        this.downloadFile(buf, 'application/csv', 'GLS.csv')
        this.loading = false
    },
    async downloadPosta() {
        this.loading = true
        
        let fontName = 'arialuni.ttf'

        const url = 'https://static.internetprint.eu/product_templates/' + fontName
        const fontBytes = await fetch(url).then(res => res.arrayBuffer())
        const logoBytes = await fetch('https://static.internetprint.eu/images/ui/ip_logonagy.jpg').then((res) => res.arrayBuffer())

        const MM2PX = 10.0
        const MM2PT = 2.834314550042052
        const FORMAT = [152 * MM2PT, 102 * MM2PT]

        let plist = this.packages
        let doc = await PDFDocument.create()

        doc.registerFontkit(fontkit)
        const customFont = await doc.embedFont(fontBytes)

        const logoImage = await doc.embedJpg(logoBytes)

        let i = 0
        for (let pid in plist) {
            let p = plist[pid]
            if (p.courier == 'posta') {
                let page = doc.addPage(FORMAT)

                let a = ['','','','','']
                a[1] = p.name
                a[2] = p.address1

                if (p.address2) {
                    a[0] = p.name
                    a[1] = p.address1
                    a[2] = p.address2
                }
                a[3] = (this.$eu(p.country) ? p.country + '-' : '') + p.postcode + ' ' + p.city + (p.region ? ' ' + p.region : '')
                if (p.country == 'US') {
                    a[3] = p.region + ' ' + p.city + ' ' + p.postcode
                }
                a[4] = countryName(p.country)


                for (let l = 0; l < 5; l++) {
                    let la = a[l]
                    let lat = this.$trans(la)
                    if (lat != lat) {la = lat + ' - ' + la }
                    page.drawText(la, {x: 7 * MM2PT, y: FORMAT[1] - (55 + l * 7) * MM2PT, size: 16, font: customFont, color: rgb(0, 0, 0), })
                }

                let s = ['InternetPrint Kft.','','Jegenye u. 6.','1107 Budapest','HUNGARY']
                for (let l = 0; l < 5; l++) {
                    let ls = s[l]
                    page.drawText(ls, {x: 7 * MM2PT, y: FORMAT[1] - (12 + l * 4) * MM2PT, size: 10, font: customFont, color: rgb(0, 0, 0), })
                }

                page.drawImage(logoImage, {x: 59 * MM2PT, y: FORMAT[1] - 16 * MM2PT, width: 35 * MM2PT, height: 10 * MM2PT })

                page.drawText('P'+p.package, {x: 69 * MM2PT, y: FORMAT[1] - 20 * MM2PT, size: 10, font: customFont, color: rgb(0, 0, 0), })
                for (let l = 0; l < 7; l++) {
                    if (p.list[l]) {
                        page.drawText(p.list[l].code, {x: 69 * MM2PT, y: FORMAT[1] - (23 + l * 3) * MM2PT, size: 10, font: customFont, color: rgb(0, 0, 0), })
                    }
                }
                i++
            }
        }

        let filename = 'posta-cimke.pdf'
        const pdfBytes = await doc.save()
        this.downloadFile(pdfBytes, 'application/pdf', filename)
        this.loading = false
    },
    downloadFile(data, mime, filename) {
        let file
        try {
            file = new File([data], filename, {type:mime})
        } catch (e) {
            file = new Blob([data], {type:mime})
        }
        let url = URL.createObjectURL(file)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
    },
    async readText(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                const result = event.target.result
                resolve(result)
            })
            reader.readAsText(file, 'Windows-1250')
        })
    },
    async uploadTracking() {
        this.loading = true
        let files = this.$refs.uploader.files
        //console.log("FILES:", files)
        if (files.length > 0) {
            let csv = await this.readText(files[0])
            csv = csv.split(/\r\n|\n/)
            if (csv.length >= 2) {
                let i = 0
                let ulist = []
                csv.forEach(r => {
                    if (i >= 1) {
                        r = r.split(';')
                        if (r.length > 10) {
                            let tnum = r[1].replace(/["]+/g, '').trim()
                            let pnum = r[6].replace(/["]+/g, '').trim()
                            pnum = pnum.split("_")
                            if (pnum.length > 1) {
                                pnum = pnum[0]
                            }
                            if (pnum[0] == 'P') {
                                pnum = pnum.replace('P','')
                                ulist.push({
                                    filter: {package: pnum},
                                    set: {trackingcode: tnum}
                                })
                            }
                        }
                    }
                    i++
                })
                if (ulist.length > 0) {
                    console.log("ULIST:", ulist)
                    const res = await this.$store.API('admin/bulkupdate', {
                        list: ulist,
                        table: this.table,
                    })
                    if (res) {
                        this.$message('info', 'Fuvarlevél szám feltöltve ' + res.result.modifiedCount + ' rendeléshez.' )
                        console.log("RES:", res)
                    } else {
                        this.$message('error', 'Művelet sikertelen!')
                    }
                } else {
                    this.$message('error', 'CSV Fájl hiba.')
                }
            } else {
                this.$message('error', 'CSV Fájl hiba.')
            }
        } else {
            this.$message('error', 'Fájl nincs kiválasztva.')
        }
        this.loading = false
    },
    async refresh() {
      this.loading = true

      if (this.param == 'go' && this.numselected > 0) {
        await this.reload()
        this.calcPackagesByPostcode()
        let message = false
        let set = false

        console.log("GO")

        set = {flag_label: 1}
        message = this.numselected +'db rendelés címkézés megkezdve.'
        
        if (set) {
          const res = await this.$store.API('admin/update', {
            set: set,
            table: this.table,
            filter: {_id: {$in: this.selectedA}},
          })
          const nres = await this.$store.NOTIFY()
          if (res) {
            this.$store.commit('clearSelect',{
              table: this.table,
            })
            this.$message('info', message)
            this.samedayStatus = 'wait_processing'
            window.setTimeout(() => {
                console.log("START PROCESSING")
                this.samedayStatus = 'processing'
            }, 5000);
          } else {
            console.error(res)
          }
        }
        this.loading = false
        this.clearSelect()
      } else {
        await this.reload()
        this.calcPackagesByPostcode()
        this.clearSelect()
      }
      document.title = this.title
      this.update++
    },
    
  }
  
}
</script>
