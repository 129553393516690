module.exports = function() {
    return {
        users: {
            title: 'Ügyfelek',
            title_single: 'Ügyfél',
            icon: 'user',
            menu_left: true,
            list_field: 'name',
            sort: 'name',
            sort_dir: 1,
            gops: {
                newpartner: {
                    title: 'Új partner/ügyfél',
                    icon: 'plus-circle',
                    route: '/add/users',
                    color: 'primary'
                },
            },
            fields: {
                name: {
                    title: 'Név',
                    classes: 'w-80',
                    list: true,
                    filter: true,
                    edit: true,
                    edit_group: 1
                },
                email: {
                    title: 'Email',
                    classes: 'w-80',
                    list: true,
                    filter: true,
                    edit: true,
                    edit_group: 1
                },
                phone: {
                    title: 'Telefonszám',
                    classes: 'w-56',
                    list: true,
                    filter: true,
                    edit: true,
                    edit_group: 1
                },
                country: {
                    type: 'text',
                    classes: 'w-24',
                    title: 'Ország',
                    list: true,
                    list_value: true,
                    filter: true,
                    filter_value: true,
                    filter_group: 2,
                    readonly: true,
                },
                language: {
                    type: 'text',
                    classes: 'w-24',
                    title: 'Nyelv',
                    list: true,
                    list_value: true,
                    filter: true,
                    filter_value: true,
                    filter_group: 2,
                    readonly: true,
                },
                password: {
                    title: 'Jelszó',
                    type: 'password'
                },
                created: {
                    type: 'time',
                    title: 'Regisztráció',
                    classes: 'w-32',
                    list: true,
                    filter: true,
                    filter_group: 2,
                    readonly: true,
                },
                roles: {
                    type: 'checklist',
                    title: 'Szerepek',
                    list: true,
                    values: {
                        admin: {title: 'Admin'}
                    }
                },
                alive: {
                    type: 'checkbox',
                    title: 'Élő',
                    default: true,
                    edit: true,
                    edit_group: 1
                },
                shop: {
                    title: 'Bolt',
                    classes: 'w-40',
                    list: true,
                    filter: true,
                    readonly: true,
                    filter_group: 3
                },
                discount: {
                    type: 'number',
                    default: 0,
                    title: 'Saját kedvezmény %',
                    classes: 'w-40',
                    edit: true,
                    edit_group: 1,
                },
                affiliate: {
                    type: 'text',
                    default: '',
                    title: 'Partner kódja (akinek jutalék jár)',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 1,
                },
                affiliate_name: {
                    type: 'text',
                    default: '',
                    title: 'Partner neve (akinek jutalék jár)',
                    classes: 'w-64',
                    edit: true,
                    edit_group: 1,
                },
                partner: {
                    type: 'checkbox',
                    default: false,
                    title: 'Partner?',
                    classes: 'w-40',
                    readonly: true,
                    list: true,
                    filter: true,
                    filter_group: 3,
                    edit: true,
                    edit_group: 2,
                },
                affiliate_owner: {
                    //if: {'partner':'true'},
                    type: 'text',
                    default: '',
                    title: 'Partner kód tulajdonosa',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 2,
                },
                user_discount: {
                    //if: {'partner':'true'},
                    type: 'number',
                    default: 0,
                    title: 'Partner ügyfelének kedvezménye %',
                    classes: 'w-40',
                    edit: true,
                    edit_group: 2,
                },
                affiliate_commission: {
                    //if: {'partner':'true'},
                    type: 'number',
                    default: 0,
                    title: 'Partner jutaléka %',
                    classes: 'w-40',
                    edit: true,
                    edit_group: 2,
                },
                sales_commission: {
                    //if: {'partner':'true'},
                    type: 'number',
                    default: 0,
                    title: 'Sales jutaléka %',
                    classes: 'w-40',
                    edit: true,
                    edit_group: 2,
                },
            }
        },
        discountcodes: {
            title: 'Kuponkódok',
            title_single: 'Kuponkód',
            icon: 'percentage',
            menu_left: true,
            list_field: 'code',
            sort: 'created',
            sort_dir: -1,
            fields: {
                code: {
                    title: 'Kód',
                    classes: 'w-80',
                    list: true,
                    filter: true,
                },
                name: {
                    title: 'Név',
                    classes: 'w-80',
                    list: true,
                    filter: true,
                },
                created: {
                    type: 'time',
                    title: 'Létrehozás',
                    classes: 'w-32',
                    list: true,
                    filter: true,
                },
                alive: {
                    type: 'checkbox',
                    title: 'Élő',
                    default: true,
                    list: true,
                },
            }
        },
        templates: {
            title: 'Sablonok',
            title_single: 'Sablon',
            icon: 'money-check',
            detail_row: true,
            menu_left: true,
            list_field: 'name',
            sort: 'created',
            sort_dir: -1,
            fields: {
                name: {
                    title: '#Szám',
                    classes: 'w-48',
                    list: true,
                    filter: true,
                },
                created: {
                    type: 'time',
                    title: 'Létrehozás',
                    classes: 'w-32',
                    list: true,
                    filter: true,
                },
                product: {
                    type: 'select',
                    title: 'Termék',
                    filter: true,
                    readonly: true,
                    list: true,
                    values: {
                        businesscard: {title: 'Névjegykártya'},
                        rug: {title: 'Szőnyeg'},
                        flyer: {title: '* Szórólap'},
                        invitation: {title: '* Meghívó'},
                    },
                },
                category: {
                    type: 'select',
                    title: 'Kategória',
                    classes: 'w-96',
                    filter: true,
                    readonly: true,
                    list: true,
                    values: {
                        funny: {title: 'Vicces'},
                        family: {title: 'Családi'},
                        pets: {title: 'Állatos'},
                        inspirational: {title: 'Inspiráló'},
                        business: {title: 'Üzleti'},
                    },
                },
                style: {
                    type: 'select',
                    title: 'Stílus (NJ)',
                    classes: 'w-96',
                    filter: true,
                    readonly: true,
                    list: true,
                    values: {
                    },
                },
                profession: {
                    type: 'select',
                    title: 'Szakma (NJ)',
                    classes: 'w-96 mb-32',
                    filter: true,
                    readonly: true,
                    list: true,
                    values: {
                    },
                },
                alive: {
                    type: 'checkbox',
                    title: 'Élő',
                    default: true,
                    list: true,
                },
            }
        },
        orders: {
            title: 'Rendelések',
            title_single: 'Rendelés',
            icon: 'shopping-cart',
            menu_left: true,
            list_field: 'code',
            sort: 'number',
            sort_dir: -1,
            detail_row: true,
            gops: {
                trackingcodes: {
                    title: 'Fuvarlevélszámok visszatöltése',
                    icon: 'upload',
                    route: '/packaging/upload',
                    color: 'yellow-700'
                },
                scan: {
                    title: 'Vonalkódos ellenőrzés',
                    icon: 'check',
                    route: '/upload/orders/scan',
                    color: 'green-400'
                },
                neworder: {
                    title: 'Új egyedi megrendelés',
                    icon: 'plus-circle',
                    route: '/add/orders',
                    color: 'primary'
                },
            },
            ops: {
                readytoprint: {
                    title: 'Előkészítés',
                    icon: 'sync',
                    route: '/command/orders/readytoprint',
                    color: 'yellow-500'
                },
                printable: {
                    title: 'Nyomtatható',
                    icon: 'print',
                    route: '/command/orders/printable',
                    color: 'green-400'
                },
                printed: {
                    title: 'Kinyomtatva',
                    icon: 'newspaper',
                    route: '/command/orders/printed',
                    color: 'blue-300'
                },
                finished: {
                    title: 'Küldésre kész',
                    icon: 'box',
                    route: '/command/orders/finished',
                    color: 'indigo-500'
                },
                sent: {
                    title: 'Kiküldve / Átvehető',
                    icon: 'hourglass-half',
                    route: '/command/orders/sent',
                    color: 'gray-500'
                },
                delivered: {
                    title: 'Átvéve',
                    icon: 'thumbs-up',
                    route: '/command/orders/delivered',
                    color: 'pink-300'
                },
                deleted: {
                    title: 'Sztornó',
                    icon: 'minus-circle',
                    route: '/command/orders/-deleted',
                    color: 'red-500',
                    statuses: ['deleted','deleted_ask','deleted_time','returned','returned_time']
                },
                p_wait: {
                    title: 'Ügyintézésre vár',
                    icon: 'question',
                    route: '/command/orders/p_wait',
                    color: 'yellow-600'
                },
                other: {
                    title: 'Egyéb Állapot',
                    icon: 'exclamation',
                    route: '/command/orders/-other',
                    color: 'yellow-800',
                    statuses: ['printed_1', 'printed_2', 'printed_3',  'printed_7',  'printed_8', 'finished_1', 'finished_2', 'finished_3', 'delivered_waitforpayment','delivered_lateradmin', 'other_1', 'other_2', 'other_3', 'other_4', 'other_5', 'new', 'archive']
                },
                download: {
                    title: 'Letöltés',
                    icon: 'file-download',
                    route: '/command/orders/-download',
                    color: 'blue-500'
                },
                shipment: {
                    title: 'Szállítás',
                    icon: 'calendar',
                    route: '/command/orders/-shipment/select',
                    buttons: [
                        {route: '/command/orders/-shipment/go/today', title: 'Minden ma'},
                        {route: '/command/orders/-shipment/go/tomorrow', title: 'Minden holnap'},
                        {route: '/command/orders/-shipment/go/datomorrow', title: 'Minden holnapután'},
                        {route: '/command/orders/-shipment/go/gls', title: 'Minden GLS -el',  bg: 'bg-yellow-500'},
                        {route: '/command/orders/-shipment/go/packeta', title: 'Minden Packeta -val',  bg: 'bg-red-500'},
                    ],
                    color: 'red-500'
                },
                packaging: {
                    title: 'Csomagolás',
                    icon: 'box',
                    route: '/packaging',
                    color: 'pink-500'
                },
                invoice: {
                    title: 'Számlázás',
                    icon: 'file-invoice-dollar',
                    route: '/command/orders/-invoice',
                    color: 'green-400'
                },
                notify: {
                    title: 'Felszólítás',
                    icon: 'mail-bulk',
                    route: '/command/orders/-notify/select',
                    buttons: [
                        {route: '/command/orders/-notify/go/payrequest', title: 'Fizetési felszólítás', bg: 'bg-red-500'},
                        {route: '/command/orders/-notify/go/delivery', title: 'Átvehető értesítés', bg: 'bg-red-700'},
                    ],
                    color: 'primary'
                },
                stat: {
                    title: 'Listázás',
                    icon: 'th-list',
                    route: '/stat/orders/-stat/',
                    buttons: [
                        {route: '/stat/orders/-stat/go/stat', title: 'Statisztika lista'},
                        {route: '/stat/orders/-stat/go/print', title: 'Kötészeti lista', bg: 'bg-red-500'},
                    ],
                    color: 'primary'
                },
            },
            fields: {
                '-search-codes': {
                    type: "hidden",
                    title: 'Vonalkód, rendelésszám,<br>vagy fuvarlevélszám keresése',
                    placeholder: 'V.kód, R.szám, fuvarlevélszám',
                    classes: 'w-80 h-16 border-2 border-green-400 text-3xl',
                    filter: true,
                    readonly: true,
                },
                number: {
                    title: 'N<sup><u>o</u></sup>',
                    type: 'int',
                    list: true,
                    readonly: true,
                },
                code: {
                    title: 'Rendelésszám',
                    placeholder: 'Pl.: 7300-102-176',
                    classes: 'w-40',
                    filter_group: 2,
                    list: true,
                    filter: true,
                    readonly: true,
                },
                'user.email': {
                    //type: 'search',
                    title: 'Ügyfél email',
                    placeholder: 'Ügyfél email',
                    classes: 'w-80',
                    //reference: 'user',
                    filter: true
                },
                product: {
                    type: 'select',
                    title: 'Termék',
                    filter: true,
                    readonly: true,
                    list: true,
                    filter_group: 1,
                    values: {
                        businesscard: {title: 'Névjegykártya'},
                        photobook: {title: 'Fotókönyv/Fotófüzet'},
                        calendar: {title: 'Naptár'},
                        rug: {title: 'Szőnyeg'},
                        photo: {title: 'Fotós termék'},
                        other: {title: 'Egyedi megrendelés'},
                        flyer: {title: '* Szórólap'},
                        invitation: {title: '* Meghívó'},
                    },
                },
                'item.settings.make': {
                    type: 'select',
                    title: 'Kivitel',
                    filter: true,
                    readonly: true,
                    list: false,
                    filter_group: 1,
                    values: {
                        book: {title: 'Könyv'},
                        booklet: {title: 'Füzet'},
                        album: {title: 'Album'},
                    },
                },
                status: { // orderstatus volt
                    type: 'select',
                    title: 'Állapot',
                    list: true,
                    filter: true,
                    filter_group: 1,
                    values: {
                        new: {title: 'Új', colort: 'text-blue-900'},
                        new_pregen: {title: '... Új/előgenerálás ...', color1: 'bg-yellow-200', color2: 'bg-yellow-300'},
                        p_wait: {title: '... Ügyintézésre vár ...', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-gray-900'},
                        p_error: {title: '... Hibás generálás ...', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-gray-900'},
                        readytoprint: {title: '... Generálás ...', color1: 'bg-red-700', color2: 'bg-red-800', colort: 'text-white'},
                        printing: {title: 'Előkészítve', color1: 'bg-yellow-200', color2: 'bg-yellow-300'},
                        printable: {title: 'Nyomtatható', color1: 'bg-green-400', color2: 'bg-green-500'},
                        printed: {title: 'Kinyomtatva', color1: 'bg-blue-200', color2: 'bg-blue-300'},
                        finished: {title: 'Küldésre kész', color1: 'bg-indigo-400', color2: 'bg-indigo-500', colort: 'text-gray-900'},
                        sent: {title: 'Kiküldve / Átvehető', color1: 'bg-gray-500', color2: 'bg-gray-600', colort: 'text-blue-200'},
                        delivered: {title: 'Átvéve', color1: 'bg-gray-500', color2: 'bg-gray-600', colort: 'text-blue-200'},
                        delivered_waitforpayment: {title: '... Átvéve, FIZETÉSRE VÁR! ...', color1: 'bg-yellow-600', color2: 'bg-yellow-700'},
                        delivered_lateradmin: {title: '... Átvéve, UTÓLAGOS RENDEZÉS! ...', color1: 'bg-yellow-600', color2: 'bg-yellow-700'},
                        returned: {title: '... Visszaérkezett ...', color1: 'bg-gray-800', color2: 'bg-gray-900', colort: 'text-white'},
                        returned_time: {title: '--- Visszaérkezett - ÁT NEM VETT ---', color1: 'bg-gray-800', color2: 'bg-gray-900', colort: 'text-white'},
                        deleted: {title: '--- Törölve (hiba, teszt) ---', color1: 'bg-gray-800', color2: 'bg-gray-900', colort: 'text-white'},
                        deleted_ask: {title: '--- Törölve (kérésre) ---', color1: 'bg-gray-800', color2: 'bg-gray-900', colort: 'text-white'},
                        deleted_time: {title: '--- Törölve (nem vette át) ---', color1: 'bg-gray-800', color2: 'bg-gray-900', colort: 'text-white'},
                        printed_1: {title: '* Kinyomtatva / 1',color1: 'bg-blue-200', color2: 'bg-blue-300', colort: 'text-red-600'},
                        printed_2: {title: '* Kinyomtatva / 2',color1: 'bg-blue-200', color2: 'bg-blue-300', colort: 'text-red-600'},
                        printed_3: {title: '* Kinyomtatva / 3',color1: 'bg-blue-200', color2: 'bg-blue-300', colort: 'text-red-600'},
                        printed_7: {title: '* Gyártás,készítés / (Szőnyeg) ',color1: 'bg-blue-50', color2: 'bg-blue-100', colort: 'text-green-600'},
                        printed_8: {title: '* Gyártás,készítés / (Megaprint) ',color1: 'bg-blue-50', color2: 'bg-blue-100', colort: 'text-green-600'},
                        finished_1: {title: '* Küldésre kész / 1', color1: 'bg-indigo-200', color2: 'bg-indigo-300', colort: 'text-red-600'},
                        finished_2: {title: '* Küldésre kész / 2', color1: 'bg-indigo-200', color2: 'bg-indigo-300', colort: 'text-red-600'},
                        finished_3: {title: '* Küldésre kész / 3', color1: 'bg-indigo-200', color2: 'bg-indigo-300', colort: 'text-red-600'},
                        other_1: {title: '* Ideiglenes / 1', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-red-300'},
                        other_2: {title: '* Ideiglenes / 2', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-red-300'},
                        other_3: {title: '* Ideiglenes / 3', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-red-300'},
                        other_4: {title: '* Ideiglenes / 4', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-red-300'},
                        other_5: {title: '* Ideiglenes / 5', color1: 'bg-yellow-600', color2: 'bg-yellow-700', colort: 'text-red-300'},
                        archive: {title: '--- Archiválva ---', color1: 'bg-gray-800', color2: 'bg-gray-900', colort: 'text-white'},
                    },
                    default: 'new'
                },
                payment_status: { // paymentstatus volt
                    type: 'select',
                    title: 'Fizetési állapot',
                    list: true,
                    filter: true,
                    filter_group: 1,
                    values: {
                        unpaid: {title: 'Nincs fizetve'},
                        paid: {title: 'Sikeres fizetés'},
                        invoice_progress: {title: '... Számlázás folyamatban ...'},
                        invoiced: {title: 'Számlázva'}, // paid volt
                        storno: {title: '--- Sztornó ---'},
                        no_invoice: {title: '--- Számla nem készül ---'},
                        error: {title: '--- Számlázási hiba ---'},
                    },
                    default: 'unpaid'
                },
                payment_type: { // shipment volt
                    type: 'select',
                    title: 'Fizetési mód',
                    list: true,
                    filter: true,
                    filter_group: 1,
                    values: {
                        cod: {title: 'Utánvét'}, // postpaid_regular volt
                        wiretransfer: {title: 'Átutalás'},
                        paypal: {title: 'Bankkártya (PayPal)'},
                        //skrill: {title: 'Bankkártya (Skrill)'}, // moneybookers volt
                        stripe: {title: 'Bankkártya (Stripe)'},
                        simple: {title: 'Bankkártya (SimplePay)'},
                        //barion: {title: 'Bankkártya (Barion)'},
                        cash: {title: 'Készpénz (Telephely)'},
                        cash_pos: {title: '* Bankkártya (Telephely)'},
                        wiretransfer_office: {title: '* Átutalás (Telephely)'}, // office_wire volt
                    },
                    default: 'simple'
                },
                courier: {
                    type: 'select',
                    title: 'Szállítási mód',
                    list: true,
                    filter: true,
                    values: {
                        gls: {title: 'GLS'},
                        sameday: {title: 'Sameday'},
                        fan: {title: 'FAN'},
                        packeta: {title: 'Packeta'},
                        samedaylocker: {title: 'Sameday / Automata (Easybox)'},
                        packetapoint: {title: 'Packeta / Átvevőhely'},
                        packeta_mpl: {title: 'Packeta / Posta (MPL)'},
                        posta: {title: 'Posta'},
                        office: {title: 'Telephely'},
                    },
                    filter_group: 1,
                    default: 'packeta'
                },
                smart_list: {
                    type: 'select',
                    title: 'Lista',
                    filter: true,
                    readonly: true,
                    list: false,
                    filter_group: 1,
                    values: {
                        '1': {title:'1: csak A3 naptár'},
                        '2': {title:'2: csak A4 naptár'},
                        '3': {title:'3: csak asztali naptár'},
                        '4': {title:'4: csak füzet'},
                        '5': {title:'5: csak könyv'},
                        '6': {title:'6: csak névjegy'},
                        '7': {title:'7: többféle termék, egyéb'},
                    }
                },
                package: {
                    title: 'Csomagszám (IP)',
                    placeholder: '6j Pl.: 169763',
                    classes: 'w-40',
                    filter_group: 3,
                    type: 'int',
                    list: true,
                    filter: true,
                    readonly: true,
                },
                created: {
                    type: 'time',
                    classes: 'w-32',
                    title: 'Megrendelés Dátuma',
                    list: true,
                    filter: true,
                    filter_group: 2,
                    readonly: true,
                },
                shipping_country: {
                    type: 'text',
                    classes: 'w-24',
                    title: 'Ország',
                    placeholder: 'Pl.: HU',
                    list: true,
                    list_value: true,
                    filter: true,
                    filter_value: true,
                    filter_group: 2,
                },
                language: {
                    type: 'text',
                    classes: 'w-24',
                    title: 'Nyelv',
                    placeholder: 'Pl.: hu',
                    list: true,
                    list_value: true,
                    filter: true,
                    filter_value: true,
                    filter_group: 2,
                    readonly: true,
                },
                trackingcode: {
                    title: 'Fuvarlevélszám',
                    classes: 'w-56',
                    placeholder: '11j Pl.: 00482259147', // ha 9 jegyű, 00 -val kell kipótolni 11 -re Pl.  00482259147, 90223484447
                    filter_group: 3,
                    filter: true,
                },
                start_shipping: {
                    type: 'time',
                    title: 'Küldés dátuma',
                    classes: 'w-32',
                    filter: true,
                    filter_group: 3,
                    readonly: true,
                },
                scanned: {
                    type: 'time',
                    title: 'Vonalkód beolvasva',
                    classes: 'w-32',
                    filter: true,
                    filter_group: 3,
                    readonly: true,
                },
                notified_sent: {
                    type: 'time',
                    title: 'Szállítási értesítő',
                    readonly: true,
                },
                notified_pay: {
                    type: 'time',
                    title: 'Fizetési felszólítás',
                    readonly: true,
                },
                shop: {
                    title: 'Bolt',
                    placeholder: 'Pl.: internetprint',
                    classes: 'w-40',
                    list: true,
                    filter: true,
                    readonly: true,
                    filter_group: 3
                },
                affiliate: {
                    title: 'Partner',
                    placeholder: 'Pl.: FOTOSMELANIA',
                    classes: 'w-40',
                    list: true,
                    filter: true,
                    readonly: true,
                    edit: true,
                    edit_group: 6,
                    filter_group: 3
                },
                archive: {
                    type: 'select',
                    title: 'Archiválva',
                    filter: true,
                    filter_group: 3,
                    values: {
                        'alive': {title: 'Élő'},
                        'archive': {title: 'Archív'},
                    },
                    default: 'alive',
                    readonly: true,
                },
                'item.pages': { // do not query this field for listing
                    delist: true,
                    type: 'hidden',
                },
                'ua': { // do not query this field for listing
                    delist: true,
                    type: 'hidden',
                },
                'location': { // do not query this field for listing
                    delist: true,
                    type: 'hidden',
                },
                '_history': { // do not query this field for listing
                    delist: true,
                    type: 'hidden',
                },
                '_original': { // do not query this field for listing
                    delist: true,
                    type: 'hidden',
                },

// Szállítási adatok

                'shipping_name': {
                    title: 'Név',
                    type: 'text',
                    edit: true,
                    edit_group: 1,
                    edit_group_title: 'Szállítási adatok'
                },
                'shipping_postcode': {
                    if: {'courier': '!samedaylocker', '-courier': '!packetapoint'},
                    title: 'IRSZ.',
                    type: 'text',
                    classes: 'w-32',
                    edit: true,
                    edit_group: 1
                },
                'shipping_city': {
                    if: {'courier': '!samedaylocker', '-courier': '!packetapoint'},
                    title: 'Település',
                    type: 'text',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 1
                },
                'shipping_region': {
                    if: {'courier': '!samedaylocker', '-courier': '!packetapoint'},
                    title: 'Régió',
                    type: 'text',
                    classes: 'w-48',
                    edit: true,
                    edit_group: 1
                },
                'shipping_address1': {
                    if: {'courier': '!samedaylocker', '-courier': '!packetapoint'},
                    title: 'Címsor 1',
                    type: 'text',
                    edit: true,
                    edit_group: 1
                },
                'shipping_address2': {
                    if: {'courier': '!samedaylocker', '-courier': '!packetapoint'},
                    title: 'Címsor 2',
                    type: 'text',
                    edit: true,
                    edit_group: 1
                },
                'locker.lockerId': {
                    if: {'courier': 'samedaylocker'},
                    title: 'Csomagautomata #ID',
                    type: 'text',
                    edit: true,
                    readonly: true,
                    edit_group: 1
                },
                'locker.name': {
                    if: {'courier': 'samedaylocker'},
                    title: 'Csomagautomata Név',
                    type: 'text',
                    edit: true,
                    readonly: true,
                    edit_group: 1
                },
                'packetapoint.id': {
                    if: {'courier': 'packetapoint'},
                    title: 'Packeta pont #ID',
                    type: 'text',
                    edit: true,
                    readonly: true,
                    edit_group: 1
                },
                'packetapoint.place': {
                    if: {'courier': 'packetapoint'},
                    title: 'Packeta pont Név',
                    type: 'text',
                    edit: true,
                    readonly: true,
                    edit_group: 1
                },
                'packetapoint.name': {
                    if: {'courier': 'packetapoint'},
                    title: 'Packeta pont cím',
                    type: 'text',
                    edit: true,
                    readonly: true,
                    edit_group: 1
                },
                '-shipping_country': {
                    title: 'Ország',
                    type: 'text',
                    classes: 'w-24',
                    edit: true,
                    edit_group: 1
                },
                contact_name: {
                    title: 'Név <small>(kapcsolattartó)</small>',
                    classes: 'w-80',
                    edit: true,
                    edit_group: 1
                },
                contact_email: {
                    title: 'Email <small>(kapcsolattartó)</small>',
                    classes: 'w-80',
                    edit: true,
                    edit_group: 1
                },
                contact_phone: {
                    title: 'Telefonszám  <small>(kapcsolattartó)</small>',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 1
                },
                '-trackingcode': {
                    title: 'Fuvarlevélszám',
                    type: 'text',
                    classes: 'w-64',
                    edit: true,
                    edit_group: 1
                },

// Számlázási adatok

                'billing_name': {
                    title: 'Név / Cégnév',
                    type: 'text',
                    edit: true,
                    edit_group: 2,
                    edit_group_title: 'Számlázási adatok'
                },
                'billing_taxnumber': {
                    title: 'Adószám',
                    type: 'text',
                    classes: 'w-64',
                    edit: true,
                    edit_group: 2
                },
                'billing_vat': {
                    title: 'EU adószám',
                    type: 'text',
                    classes: 'w-64',
                    edit: true,
                    edit_group: 2
                },
                'billing_postcode': {
                    title: 'IRSZ.',
                    type: 'text',
                    classes: 'w-32',
                    edit: true,
                    edit_group: 2
                },
                'billing_city': {
                    title: 'Település',
                    type: 'text',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 2
                },
                'billing_region': {
                    title: 'Régió',
                    type: 'text',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 2
                },
                'billing_address1': {
                    title: 'Címsor 1',
                    type: 'text',
                    edit: true,
                    edit_group: 2
                },
                'billing_address2': {
                    title: 'Címsor 2',
                    type: 'text',
                    edit: true,
                    edit_group: 2
                },
                'billing_country': {
                    title: 'Ország',
                    type: 'text',
                    classes: 'w-24',
                    edit: true,
                    edit_group: 2
                },

// Rendelés adat

                '-status': {
                    title: 'Állapot',
                    type: 'select',
                    edit: true,
                    edit_group: 3,
                },
                '-payment_type': {
                    title: 'Fizetési mód',
                    type: 'select',
                    edit: true,
                    edit_group: 3,
                },
                '-payment_status': {
                    title: 'Fizetési állapot',
                    type: 'select',
                    edit: true,
                    edit_group: 3,
                },
                '-courier': {
                    title: 'Szállító',
                    type: 'select',
                    edit: true,
                    edit_group: 3,
                },
                'item.settings.pcs': {
                    if: {'item.settings.product': '!other'},
                    title: 'Darabszám',
                    type: 'number',
                    edit: true,
                    edit_group: 3,
                    classes: 'w-24',
                    readonly: true,
                },
                'item.quote.pcs': {
                    if: {'item.settings.product': 'other'},
                    title: 'Termék(ek) mennyisége',
                    type: 'text',
                    edit: true,
                    edit_group: 3,
                    classes: 'w-80',
                },
                'item.quote.name': {
                    if: {'item.settings.product': 'other'},
                    title: 'Termék neve',
                    type: 'text',
                    edit: true,
                    edit_group: 3,
                },
                'item.settings.shipping_free': {
                    title: 'Ingyen szállítás',
                    type: 'checkbox',
                    edit: true,
                    edit_group: 3,
                    readonly: true,
                },
                'item.settings.shipping_free_extra': {
                    title: 'Extra szállítási költség sincs (GLS +1500Ft, MPL +590Ft)',
                    type: 'checkbox',
                    edit: true,
                    edit_group: 3,
                    readonly: true,
                },
                'item.quote.product_price_before': {
                    /*if: {'item.quote.product_price_before':'$exists'},*/
                    title: 'Termék ár (Kuponkód előtt)',
                    type: 'number',
                    edit: true,
                    edit_group: 3,
                    classes: 'w-32',
                    readonly: false, // átírható, de termék módosításnál számított
                },
                'item.quote.currency': {
                    title: 'Pénznem',
                    type: 'text',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 3,
                    readonly: false //{'item.settings.product': '!other'}, // nem módosulhat!
                },
                'discountcode': {
                    title: 'Kuponkód',
                    type: 'text',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 3,
                },
                'item.quote.discountcode': {
                    title: 'Érvényesített kuponkód',
                    type: 'text',
                    classes: 'w-56',
                    edit: true,
                    edit_group: 3,
                    readonly: true,
                },
                'item.quote.product_price': {
                    title: 'Termék ár (kuponkód után)',
                    type: 'number',
                    edit: true,
                    edit_group: 3,
                    classes: 'w-32',
                    readonly: true, // számított!
                },
                'item.quote.shipping_price': {
                    title: 'Szállítási költség',
                    type: 'number',
                    edit: true,
                    edit_group: 3,
                    classes: 'w-32',
                    readonly: true, // számított!
                },
                'item.quote.price': {
                    title: 'Teljes ár',
                    type: 'number',
                    edit: true,
                    edit_group: 3,
                    classes: 'w-32',
                    readonly: true, // számított!
                },
                'note': {
                    title: 'Megjegyzés',
                    type: 'textarea',
                    edit: true,
                    edit_group: 3,
                    edit_group_title: 'Rendelés adatok'
                },

// Névjegy A oldal

                'item.pages.0.data.layers.company.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Cég',
                    type: 'text',
                    edit: true,
                    edit_group: 4,
                    edit_group_title: 'Névjegy A oldal',
                },
                'item.pages.0.data.layers.slogan.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Szlogen',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.slogan2.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Szlogen 2',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.monogram.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Monogram',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.name.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Név',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.jobtitle.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Titulus',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.jobtitle2.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Titulus 2',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.address1.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Címsor 1',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.address2.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Címsor 2',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.phone.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Telefon',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.fax.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Fax',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.mobile.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Mobil',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.email.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Email',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.web.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Web',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.data1.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Adat 1',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.data2.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Adat 2',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },
                'item.pages.0.data.layers.data3.text': {
                    if: {'item.settings.product': 'businesscard'},
                    title: 'Adat 3',
                    type: 'text',
                    edit: true,
                    edit_group: 4
                },


// Névjegy B oldal

                'item.pages.1.data.layers.company.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Cég',
                    type: 'text',
                    edit: true,
                    edit_group: 5,
                    edit_group_title: 'Névjegy B oldal',
                },
                'item.pages.1.data.layers.slogan.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Szlogen',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.slogan2.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Szlogen 2',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.monogram.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Monogram',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.name.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Név',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.jobtitle.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Titulus',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.jobtitle2.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Titulus 2',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.address1.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Címsor 1',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.address2.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Címsor 2',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.phone.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Telefon',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.fax.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Fax',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.mobile.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Mobil',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.email.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Email',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.web.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Web',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.data1.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Adat 1',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.data2.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Adat 2',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },
                'item.pages.1.data.layers.data3.text': {
                    if: {'item.settings.product': 'businesscard', 'item.pages.1.template.name':'!empty'},
                    title: 'Adat 3',
                    type: 'text',
                    edit: true,
                    edit_group: 5
                },


                'invoice_note': {
                    if: {'item.settings.product': 'other'},
                    title: 'Számla megjegyzés',
                    type: 'text',
                    edit: true,
                    edit_group: 2,
                },
            }
        },
    }
}
